// src/routes/routesAndLinks.jsx
import React, { lazy } from "react";
// STATIC PAGES
import Home from "../Pages/Home.jsx";
import About from "../Pages/About.jsx";
import Events from "../Pages/Events.jsx";
import Notices from "../Pages/Notices.jsx";
import OurDojos from "../Pages/OurDojos.jsx";
import ErrorPage from "../Pages/error/ErrorPage.jsx";
import Galeria from "../Pages/Galeria.jsx";
import Kyokushin from "../Pages/Kyokushin.jsx";

// Lazy load your pages
const EventsCategory = lazy(() => import("../Pages/EventsCategory.jsx"));
const Details = lazy(() => import("../../containers/notice/Details.jsx"));
const NbyCat = lazy(() =>
  import("../../containers/notice/Notices_by_category.jsx")
);

const routesAndLinks = [
  { path: "/", element: <Home />, title: "Inicio" },
  { path: "/quienes-somos", element: <About />, title: "Quiénes Somos" },
  { path: "/actividades", element: <Events />, title: "Actividades" },
  { path: "/noticias", element: <Notices />, title: "Noticias" },
  { path: "/nuestros-dojos", element: <OurDojos />, title: "Nuestros Dojos" },
  { path: "/galeria", element: <Galeria />, title: "Galería" },
  { path: "/kyokushin", element: <Kyokushin />, title: "Kyokushin" },
  {
    path: "/actividades/categoria/:slug",
    element: <EventsCategory />,
    title: null,
  },
  { path: "/noticias/:slug", element: <Details />, title: null },
  { path: "/noticias/categoria/:slug", element: <NbyCat />, title: null },
  { path: "/*", element: <ErrorPage />, title: null },
  { path: "/auth/*", element: <ErrorPage />, title: null, protected: true },
  { path: "/admin/*", element: <ErrorPage />, title: null, protected: true },
  { path: "/api/*", element: <ErrorPage />, title: null, protected: true },
];

export default routesAndLinks;
