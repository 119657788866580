// src/components/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAccess } from '../../context/AccessContent'; // Asegúrate de que la ruta sea correcta

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { hasAccess } = useAccess();

  return hasAccess ? (
    Component
  ) : (
    <Navigate to="/error" replace />
  );
};

export default ProtectedRoute;