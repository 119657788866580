export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const GET_BLOG_LIST_SUCCESS = 'GET_BLOG_LIST_SUCCESS';
export const GET_BLOG_LIST_FAIL = 'GET_BLOG_LIST_FAIL';

export const GET_AUTHOR_BLOG_LIST_SUCCESS = 'GET_AUTHOR_BLOG_LIST_SUCCESS';
export const GET_AUTHOR_BLOG_LIST_FAIL = 'GET_AUTHOR_BLOG_LIST_FAIL';

export const GET_BLOG_LIST_CATEGORIES_SUCCESS = 'GET_BLOG_LIST_CATEGORIES_SUCCESS';
export const GET_BLOG_LIST_CATEGORIES_FAIL = 'GET_BLOG_LIST_CATEGORIES_FAIL';

export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL';

export const GET_SEARCH_BLOG_SUCCESS = 'GET_SEARCH_BLOG_SUCCESS';
export const GET_SEARCH_BLOG_FAIL = 'GET_SEARCH_BLOG_FAIL';

export const GET_RELATED_POSTS_SUCCESS = 'GET_RELATED_POSTS_SUCCESS';
export const GET_RELATED_POSTS_FAIL = 'GET_RELATED_POSTS_FAIL';

export const FETCH_RELATED_POSTS_REQUEST = 'FETCH_RELATED_POSTS_REQUEST';
export const FETCH_RELATED_POSTS_SUCCESS = 'FETCH_RELATED_POSTS_SUCCESS';
export const FETCH_RELATED_POSTS_FAILURE = 'FETCH_RELATED_POSTS_FAILURE';
