import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/img/logo.jpg";

const BlackBeltList = ({ dojos }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!dojos) return;

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [dojos]);

  if (!dojos) return null;

  // Ordenar los dojos por grado en orden descendente
  const sortedDojos = dojos
    .filter(
      (dojo) =>
        dojo.is_branch_chief === "No" &&
        dojo.is_dojoOperator === "No" &&
        dojo.is_Director === "No"
    )
    .sort((a, b) => b.grado.localeCompare(a.grado)); // Orden descendente por grado

  return (
    <section className="BDMatsushima">
      <h3 className="section-heading text-center">
        Nuestros Cinturones Negros 
      </h3>
      <div className="container">
        <div className="row">
          {sortedDojos.map((dojo) => (
            <div key={dojo.id} className="col-md-4 mt-2">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  {loading ? (
                    <div
                      className="skeleton-loading w-50"
                      style={{ height: "200px" }}
                    ></div>
                  ) : (
                    <img
                      className="card-img-top"
                      src={dojo.profile_image || logo}
                      alt={`${dojo.cargo} ${dojo.full_name}`}
                      preload="auto"
                      height={250}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = logo;
                      }}
                    />
                  )}
                </div>
                <div className="card-body pt-0">
                  {loading ? (
                    <div
                      className="skeleton-loading w-100 mb-2"
                      style={{ height: "20px" }}
                    ></div>
                  ) : (
                    <>
                      <h5 className="card-title text-center">{`${dojo.cargo} ${dojo.full_name}`}</h5>
                      <strong className="card-text text-center">
                        {dojo.grado} Internacional
                      </strong>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

BlackBeltList.propTypes = {
  dojos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      profile_image: PropTypes.string,
      cargo: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
      is_branch_chief: PropTypes.string.isRequired,
      is_dojoOperator: PropTypes.string.isRequired,
      is_Director: PropTypes.string.isRequired,
      grado: PropTypes.string.isRequired, // Asegúrate de que el campo grado está definido en los datos
      zona: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default BlackBeltList;
