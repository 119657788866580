import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import routesAndLinks from './index';

const AppRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      {routesAndLinks.map((route, index) => {
        const RouteComponent = route.protected ? (
          <Route
            key={index}
            path={route.path}
            element={<ProtectedRoutes element={route.element} />}
          />
        ) : (
          <Route
            key={index}
            path={route.path}
            element={route.element}
          />
        );

        return RouteComponent;
      })}
    </Routes>
  </Suspense>
);

export default AppRoutes;
