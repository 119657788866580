// src/context/AccessContext.jsx
import React, { createContext, useContext, useState } from 'react';

const AccessContext = createContext();

export const AccessProvider = ({ children }) => {
  const [hasAccess, setHasAccess] = useState(false); // Ajusta según tu lógica de acceso

  // Aquí puedes definir funciones para actualizar el estado de acceso
  const grantAccess = () => setHasAccess(true);
  const revokeAccess = () => setHasAccess(false);

  return (
    <AccessContext.Provider value={{ hasAccess, grantAccess, revokeAccess }}>
      {children}
    </AccessContext.Provider>
  );
};

export const useAccess = () => useContext(AccessContext);
