import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store.js";
import Browser from "./components/Routes/Routes.js";
import { AccessProvider } from "./context/AccessContent.jsx";
function App() {
  return (
    <AccessProvider>
      <Provider store={store}>
        <Router>
          <Browser />
        </Router>
      </Provider>
    </AccessProvider>
  );
}

export default App;
