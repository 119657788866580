import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_dojos } from "../../redux/actions/dojos/dojos";
import BranchDojoCards from "../../containers/about/BranchDojoCards";

const BranchDojo = () => {
  const dispatch = useDispatch();
  const dojos = useSelector((state) => state.dojos.dojo_list);

  useEffect(() => {
    const fetchDojos = async () => {
      try {
        dispatch(get_dojos());
      } catch (error) {
        console.error("Failed to fetch dojos:", error);
      }
    };

    fetchDojos();
  }, [dispatch]);

  return (
    <div>
      {dojos && dojos.length > 0 ? (
        <BranchDojoCards dojos={dojos} />
      ) : (
        <p>No dojos available</p>
      )}
    </div>
  );
};

export default BranchDojo;
